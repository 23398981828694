import { Close } from 'icons/svg';
import WagersPage from 'pages/wagers/wagers';
import { GameProduct } from 'services/get-game-products/get-game-products';

export type BuyCreditsModalProps = {
  onClose(): void;
  onOpenQuickCredits(): void;
  depositResult?: string;
  show: boolean;
  instantPackage?: GameProduct;
};
const BuyCreditsModal = ({
  onClose,
  show,
  instantPackage,
  onOpenQuickCredits,
}: BuyCreditsModalProps) => (
  <div className='p-2' style={{ minHeight: '800px', overflowY: 'scroll' }}>
    <div className={'d-flex flex-row justify-content-end'}>
      <Close size={'16px'} color={'#ffffff'} onClick={onClose} />
    </div>
    <div className='d-flex flex-column justify-content-center'>
      <WagersPage
        closeAll={onClose}
        isModal={true}
        instantPackage={instantPackage}
        onOpenQuickCredits={onOpenQuickCredits}
      />
    </div>
  </div>
);
export default BuyCreditsModal;
