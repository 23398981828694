import { Close, LocationPin } from 'icons/svg';
import { Button } from 'components/atoms/button';
import React from 'react';

type NoLocationAvailableModalProps = {
  onClose?(): void;
  show: boolean;
};
export const LocationNotAllowedModal = ({
  onClose,
  show,
}: NoLocationAvailableModalProps) => {
  const onCloseModal = () => {
    onClose();
  };

  return (
    <div className='p-2' style={{ minHeight: '800px', overflowY: 'scroll' }}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{'Off limits'}</h2>
      </div>
      <div className={'d-flex flex-column gap-1'}>
        <div
          className={
            'd-flex flex-row justify-content-center py-2 gap-2 align-items-center'
          }
        >
          <LocationPin size={'56'} />
        </div>
        <div>
          {' '}
          Sorry, you can't wager from this location. Please contact our Player
          Experience Team at{' '}
          <span style={{ color: 'red' }}>support@horseplay.com </span> if you
          need more details.
        </div>
      </div>
      <div className={'d-flex flex-row gap-2'}>
        <Button text='Ok, got it!' onClick={onCloseModal} />
      </div>
    </div>
  );
};
export default LocationNotAllowedModal;
