import { Close } from '../../../../../icons/svg';
import { toCurrency } from '../../../../../common/bspot-shared';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useAuth } from '../../../../../contexts/authentication';
import BuyCreditsModal from '../../../modal-buy-credits/modal-buy-credits';
import './styles.scss';
import {
  buyCredits,
  BuyCreditsPayload,
} from '../../../../../services/buy-credits/buy-credits';
import {
  GameProduct,
  GameProducts,
} from '../../../../../services/get-game-products/get-game-products';
import {
  addCreditsToCartAnalyticsEvent,
  creditPurchaseAnalyticsEvent,
} from '../../../../../common/utils';
import { AppLoader } from '../../../../molecules/app-loader/app-loader';
import { ModalDisplayState } from 'components/organisms/buy-credits/modals/quick-credits-modal-manager/quick-credits-modal-manager';
import {
  BspotCashierMessage,
  postNativeMessage,
} from 'services/native-ios/native-ios';
import { useSdk } from 'contexts/sdk/sdk';
import { BspotAlert } from 'components/molecules/alerts/alert';

type QuickCreditsModalProps = {
  isLowCreditMode: boolean;
  hide: () => void;
  balances: {
    deposits_count: number;
    credits: number;
    credits_pending: number;
    credits_purchased_count: number;
    credits_last_order: number;
    cash: number;
    shouldConvertCash: boolean;
    shouldDeposit: boolean;
  };
  gameProducts: GameProducts['gameProducts'];
  setDisplayState: Dispatch<SetStateAction<ModalDisplayState>>;
  stage: 'initial' | 'slow' | 'fast' | 'immediate';
  spinsRemaining: number;
  showWagerDetails: (gameProduct: GameProduct) => void;
  wager?: number;
};
export const QuickCreditsModal = ({
  isLowCreditMode,
  hide,
  balances,
  gameProducts,
  setDisplayState,
  stage,
  spinsRemaining,
  showWagerDetails,
}: QuickCreditsModalProps) => {
  const { getEmbedded } = useSdk();
  const { auth } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [lastOrderAvailable, setLastOrderAvailable] = useState(null);
  const [canAffordLastPackage, setCanAffordLastPackage] = useState(false);
  const [rebuy, setRebuy] = useState(null);
  const [maxRebuy, setMaxRebuy] = useState(null);
  const [showFullBuyCredits, setShowFullBuyCredits] = useState(false);
  const [feedback, setFeedback] = useState(null);
  useLayoutEffect(() => {
    const noImmediatePackages = gameProducts.every(
      (product) => product.expected_wait !== 'IMMEDIATE',
    );

    const lastOrder = balances.credits_last_order;
    const lastOrderedPackage = gameProducts.find(
      (product) => product?.cost === lastOrder,
    );

    setCanAffordLastPackage(balances.cash >= lastOrderedPackage?.cost);

    const lastOrderAvailable = gameProducts.find(
      (product) =>
        product?.cost === lastOrder && product.expected_wait === 'IMMEDIATE',
    );
    setLastOrderAvailable(lastOrderAvailable);
    setRebuy(lastOrderedPackage);
    const affordableProducts = gameProducts
      .filter(
        (product) =>
          (product.expected_wait === 'IMMEDIATE' || noImmediatePackages) &&
          product?.cost <= balances.cash,
      )
      .sort((a, b) => a?.cost - b?.cost)
      .reverse();
    setMaxRebuy(affordableProducts && affordableProducts[0]);
  }, [balances.cash, balances.credits_last_order, gameProducts]);

  useEffect(() => {
    if (rebuy && maxRebuy) {
      if (
        rebuy.expected_wait !== 'IMMEDIATE' &&
        maxRebuy.expected_wait !== 'IMMEDIATE'
      ) {
        setShowFullBuyCredits(true);
      }
    }
  }, [rebuy, maxRebuy]);

  const shouldDisplayMaxRebuy = () => {
    if (!maxRebuy) {
      return false;
    }
    if (rebuy?.cost === maxRebuy?.cost) {
      return false;
    }
    if (maxRebuy?.cost < rebuy?.cost) {
      return false;
    }
    if (rebuy?.cost !== maxRebuy?.cost) {
      return true;
    }
  };

  const onBuy = async (selectedPackage) => {
    setIsLoading(true);
    addCreditsToCartAnalyticsEvent({
      product: selectedPackage,
      playerId: auth.session.playerId,
    });
    try {
      let payload: BuyCreditsPayload = {
        game_product_id: selectedPackage.id,
      };
      const res = await buyCredits(payload, auth.session);
      console.log(res);
      if (res?.status === 403) {
        setDisplayState('locationNotAllowed');
        setIsLoading(false);
        return;
      }
      const d = new Date().toISOString();
      if (getEmbedded) {
        postNativeMessage(
          BspotCashierMessage.BUY_CREDITS_SUCCESS,
          JSON.stringify(selectedPackage),
        );
        postNativeMessage({
          name: 'package_revenue',
          value: selectedPackage.cost * (6 / 100),
        });
      }

      creditPurchaseAnalyticsEvent({
        selectedPackage: selectedPackage,
        playerId: auth?.session?.playerId,
      });

      setIsLoading(false);
      hide();
      setTimeout(function () {
        postNativeMessage(
          BspotCashierMessage.CLOSE,
          'CLOSE Buy Credits Success',
        );
      }, 1500);
      return;
    } catch (error) {
      if (error?.ok === false && error?.data?.latitude === 0) {
        setDisplayState('locationNotAvailable');
        setIsLoading(false);
      }
      hide();
      setFeedback(error.message);
      return error.message;
    }
  };

  if (
    showFullBuyCredits ||
    (gameProducts.length > 0 && !rebuy && !shouldDisplayMaxRebuy)
  ) {
    return (
      <BuyCreditsModal
        show
        onClose={() => {
          setShowFullBuyCredits(false);
        }}
        onOpenQuickCredits={null}
      />
    );
  }

  return (
    <>
      <div className='quick-credits-modal-overlay'>
        {gameProducts.length > 0 && (
          <div
            className='quick-credits-modal'
            style={{ minHeight: '800px', overflowY: 'scroll' }}
          >
            <div className={'d-flex flex-row justify-content-end'}>
              {/*<Close size={'16px'} color={'#ffffff'} onClick={hide} />*/}
            </div>

            <>
              {isLowCreditMode && (
                <>
                  {stage === 'slow' && (
                    <img
                      src='/assets/images/low-credit-1.webp'
                      alt='low-credit-1'
                    />
                  )}
                  {stage === 'fast' && (
                    <img
                      src='/assets/images/low-credit-2.webp'
                      alt='low-credit-2'
                    />
                  )}
                  {stage === 'immediate' && (
                    <img
                      src='/assets/images/low-credit-3.webp'
                      alt='low-credit-3'
                    />
                  )}
                  <h2 className='title'>LOW CREDITS</h2>
                  {spinsRemaining > 0 && (
                    <h4 className='sub-title'>
                      Only{' '}
                      <span className={stage}>
                        {spinsRemaining} SPIN{spinsRemaining !== 1 && 'S'}
                      </span>{' '}
                      remaining at current bet. Buy now!
                    </h4>
                  )}
                  {spinsRemaining === 0 && (
                    <h4 className='sub-title'>Buy now to keep playing!</h4>
                  )}
                </>
              )}

              {!isLowCreditMode && (
                <>
                  <img src='/assets/images/quick-buy.webp' alt='quick-buy' />
                  <h2 className='title'>QUICK CREDITS</h2>
                  <h4 className='sub-title'>Buy now to keep playing!</h4>
                </>
              )}

              {!isLoading && (
                <div className='cards-rebuy'>
                  {rebuy && rebuy.expected_wait === 'IMMEDIATE' && (
                    <div className='card-rebuy'>
                      <img
                        src='/assets/images/rebuy.webp'
                        loading='lazy'
                        alt='rebuy'
                      />
                      <p className='card-rebuy-title'>REBUY</p>
                      <div className={'d-flex flex-column'}>
                        <p
                          className='card-rebuy-sub-title'
                          style={{
                            margin:
                              !canAffordLastPackage && lastOrderAvailable
                                ? ' 0 0 5px 0'
                                : '0 0 25px 0',
                          }}
                        >
                          Last credit amount purchased.
                        </p>
                        {!canAffordLastPackage && lastOrderAvailable && (
                          <p
                            className='card-rebuy-sub-title-deposit'
                            style={{ color: '#d70022', margin: ' 0 0 5px 0' }}
                          >
                            Deposit required.
                          </p>
                        )}
                      </div>
                      <button
                        disabled={isLoading}
                        className='card-rebuy-button'
                        onClick={() =>
                          canAffordLastPackage
                            ? onBuy(rebuy)
                            : setDisplayState('deposit')
                        }
                      >
                        {toCurrency(rebuy?.cost, { symbol: '$', decimals: 0 })}
                      </button>
                      <button
                        className='card-rebuy-button-wager'
                        onClick={() => showWagerDetails(rebuy)}
                      >
                        See wager details
                      </button>
                    </div>
                  )}
                  {shouldDisplayMaxRebuy() && (
                    <div className='card-rebuy'>
                      <img
                        src='/assets/images/max-rebuy.webp'
                        loading='lazy'
                        alt='max-rebuy'
                      />
                      <p className='card-rebuy-title'>MAX REBUY</p>
                      <p
                        className='card-rebuy-sub-title'
                        style={{
                          margin: '0 0 25px 0',
                        }}
                      >
                        Maximum credit amount available.
                      </p>
                      <button
                        disabled={isLoading}
                        className='card-rebuy-button'
                        onClick={() => onBuy(maxRebuy)}
                      >
                        {toCurrency(maxRebuy?.cost, {
                          symbol: '$',
                          decimals: 0,
                        })}
                      </button>
                      <button
                        className='card-rebuy-button-wager'
                        onClick={() => showWagerDetails(maxRebuy)}
                      >
                        See wager details
                      </button>
                    </div>
                  )}
                </div>
              )}

              {isLoading && (
                <>
                  {feedback && (
                    <div
                      onClick={() => {
                        setIsLoading(false);
                      }}
                      className='d-flex flex-column justify-content-center align-items-center'
                      style={{
                        display: 'flex',
                        padding: '135px 0',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <BspotAlert type={'error'} message={feedback} />
                    </div>
                  )}

                  {!feedback && (
                    <div
                      className='d-flex flex-column justify-content-center align-items-center'
                      style={{
                        display: 'flex',
                        padding: '135px 0',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <AppLoader fullHeight={false} />
                    </div>
                  )}
                </>
              )}

              <p className='info-text'>
                Selected amount will be deducted from Cash Account.
                <br />
                All horse race wagers are final.
              </p>
              <button
                className='more-credit-options'
                onClick={() => setShowFullBuyCredits(true)}
              >
                More Credit Options
              </button>
            </>
          </div>
        )}
        {gameProducts.length === 0 && (
          <div
            className='quick-credits-modal'
            style={{ minHeight: '800px', overflowY: 'scroll' }}
          >
            <div className={'d-flex flex-row justify-content-end'}>
              <Close size={'16px'} color={'#ffffff'} onClick={hide} />
            </div>
            <div
              className='d-flex flex-column justify-content-center align-items-center'
              style={{
                display: 'flex',
                padding: '150px 0',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AppLoader fullHeight={false} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
